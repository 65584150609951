/* eslint-disable no-useless-escape */
import moment from "moment/moment";
import { cnyFlag, eurFlag, indFlag, sarFlag, uaeFlag, usaFlag } from "./cdn";

//Regex
export function validEmail(elementValue) {
  // var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  var emailPattern =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return emailPattern.test(elementValue);
}

export function getVoucherPropertyTypeMetaData(propertyType, bedroom) {
  if (propertyType === "Studio") {
    return propertyType;
  } else if (propertyType === "Apartment") {
    switch (bedroom) {
      case 1:
        return "1BR";
      case 2:
        return "2BR";
      case 3:
        return "3BR";
      case 4:
        return "4BR";
      case 5:
        return "5BR";
      default:
        return propertyType;
    }
  } else return propertyType;
}

export const changeDateFormat = (newDate) => {
  return moment(newDate).format("MMM YYYY");
};

export function capitalCased(inputStr) {
  return inputStr
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function dashCased(inputStr) {
  return inputStr
    .toLowerCase()
    .split(" ")
    .map((word) => word.replace(/[^a-zA-Z0-9]/g, "")) // Remove non-alphanumeric characters
    .join("-");
}

export function updateUrlByNeighborhoods(neighborhoods) {
  if (Array.isArray(neighborhoods)) {
    let url = '';
    const selectAll = neighborhoods.findIndex((ele) => ele?.label == "All");
    if (selectAll === -1) {
      url = neighborhoods
        .map(n => decodeURIComponent(n?.label).replace(/\s/g, "-").toLowerCase()).join(',');
    } else {
      url = 'all';
    }
    return url
  }
}

export function updateDropDownValueInUpperCase(neighbourhood) {
  const cityDetail = JSON.parse(localStorage.getItem('cityDetails'));
  const neighbourhoodData = cityDetail?.neighborhoods?.filter((item) => {
    return item?.name?.toLowerCase() === neighbourhood?.replaceAll('-', " ")?.toLowerCase()
  }).map((item) => item.name);

  if (neighbourhood?.toLocaleLowerCase() === "all") {
    return "All"
  } else {
    return neighbourhoodData;
  }
}

export function isNeighborhoodExists(neighbourhoods){
  return neighbourhoods?.split(',').every(item =>updateDropDownValueInUpperCase(item)?.toString()
    );
}


export function updateTowersDropDownValueInUpperCase(tower) {
  const cityDetail = JSON.parse(localStorage.getItem('cityDetails'));
  const towerData = cityDetail?.neighborhoods
  ?.flatMap(neighborhood => neighborhood.towers).filter((item) => {
    return item?.toLowerCase() === tower?.replaceAll('-', " ")?.toLowerCase()
  }).map((item) => item);

  if (tower?.toLocaleLowerCase() === "all") {
    return "All"
  } else {
    return [towerData[0]];
  }
}

export function isTowerExists(towers){
  return towers?.split(',').every(item =>updateTowersDropDownValueInUpperCase(item)?.toString()
    );
}

export const countryNames = [
  {
    name: "AED",
    image: uaeFlag,
  },
  {
    name: "CNY",
    image: cnyFlag,
  },
  {
    name: "EUR",
    image: eurFlag,
  },
  {
    name: "INR",
    image: indFlag,
  },
  {
    name: "SAR",
    image: sarFlag,
  },
  {
    name: "USD",
    image: usaFlag,
  },
];

export const topNavBarAllowedPaths = ["/riyadh", "/dubai", "/abu-dhabi"];

export const getRecaptchaValidationPayload = (token, action) => {
  return {
    recaptcha_validation: {
      token,
      action
    }
  }
}

export const getPaymentTimelineState = (status) => {
  let state = "scheduled";
  switch(status){
    case "SUCCEEDED":
      state = "completed";
      break;
    case "FAILED":
      state = "failed";
      break;
    case "NEXT_DUE":
      state = "next-due";
      break;
    default:
      break;
  }
  return state;
}