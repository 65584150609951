import { create } from "zustand";
import {ANCILLARY_SERVICES} from "../constant";
import zukeeper from "zukeeper";
import { LOCAL_STORAGE_ITEMS } from "../../../utils/constants";

const initialState = {
  currentPage: "",
  isBackButtonEnabled: false,

  firstName: undefined,
  lastName: undefined,
  email: undefined,
  countryCode: undefined,
  country: undefined,
  phoneNo: undefined,
  whatsAppNo: undefined,
  guestCount: 1,
  preferredCheckInTime: undefined,
  purposeOfVisit: undefined,
  comment: undefined,
  voucherCode: undefined,
  bookingDetails: {
    pms_listing_id: "",
    check_in_date: "",
    check_out_date: "",
    currency: "AED",
  },
  propertyDetails: {
    accommodates: 0,
    bedrooms: 0,
    city: "",
    neighborhood: "",
    pictures: [],
    title: "",
  },
  lineItems: [],
  ancillaryServices: [],
  bookingCode: "",
  infoText: "",

  isGuestDetailsEditable: false,

  isFirstNameEditable: true,
  isLastNameEditable: true,
  isEmailEditable: true,
  isCountryEditable: true,
  isPhoneNoEditable: true,
  isWhatsAppNoEditable: true,
  isGuestCountEditable: true,

  isVoucherDetailsEditable: false,

  isTripInfoEditable: false,
  isPreferredCheckInTimeEditable: true,
  isPurposeOfVisitEditable: true,
  isCommentEditable: true,

  isGuestDetailsValid: false,
  isFirstNameValid: undefined,
  isLastNameValid: undefined,
  isEmailValid: undefined,
  isCountryValid: undefined,
  isPhoneNoValid: undefined,
  isWhatsAppNoValid: undefined,
  isGuestCountValid: undefined,

  isAgree: false,

  isExpanded: false,
  steps: [],
  expiresAt: undefined,
  cleaningDays: [],
  cleaningTimes: undefined,
  isCleaningAdded: undefined,
  paymentPlanType: "UPFRONT_PAYMENT",
  selectedPaymentOption: {
    key: "",
    label: ""
  }
};

const usePaymentCheckoutStore = create(
  zukeeper((set) => ({
    ...initialState,

    setCurrentPage: (currentPage) => set(() => ({ currentPage: currentPage })),
    setIsBackButtonEnabled: (isBackButtonEnabled) =>
      set(() => ({ isBackButtonEnabled: isBackButtonEnabled })),

    setIsAgree: (isAgree) => set(() => ({ isAgree: isAgree })),

    setFirstName: (firstName) => set(() => ({ firstName: firstName })),
    setLastName: (lastName) => set(() => ({ lastName: lastName })),
    setEmail: (email) => set(() => ({ email: email })),
    setCountryCode: (countryCode) => set(() => ({ countryCode: countryCode })),
    setCountry: (country) => set(() => ({ country: country })),
    setPhoneNo: (phoneNo) => set(() => ({ phoneNo: phoneNo })),
    setwhatsAppNo: (whatsAppNo) => set(() => ({ whatsAppNo: whatsAppNo })),
    setGuestCount: (guestCount) => set(() => ({ guestCount: guestCount })),
    setVoucherCode: (voucherCode) => set(() => ({ voucherCode: voucherCode })),
    setPreferredCheckInTime: (preferredCheckInTime) =>
      set(() => ({ preferredCheckInTime: preferredCheckInTime })),
    setPurposeOfVisit: (purposeOfVisit) =>
      set(() => ({ purposeOfVisit: purposeOfVisit })),
    setComment: (comment) => set(() => ({ comment: comment })),
    setBookingDetails: (bookingDetails) =>
      set(() => {
        localStorage.setItem(LOCAL_STORAGE_ITEMS.BOOKING_DETAILS, JSON.stringify(bookingDetails));
        return {
          bookingDetails: bookingDetails,
        };
      }),
    setPropertyDetails: (propertyDetails) =>
      set(() => {
        localStorage.setItem(LOCAL_STORAGE_ITEMS.PROPERTY_DETAILS, JSON.stringify(propertyDetails));
        return {
          propertyDetails: propertyDetails,
        };
      }),
    setLineItems: (lineItems) =>
      set(() => {
        localStorage.setItem(LOCAL_STORAGE_ITEMS.LINE_ITEMS, JSON.stringify(lineItems));
        return {
          lineItems: lineItems,
        };
      }),
    setInfoText: (infoText) => set(() => ({ infoText: infoText })),
    setAncillaryServices: (ancillaryServices) =>
      set(() => {
        localStorage.setItem(ANCILLARY_SERVICES, JSON.stringify(ancillaryServices));
        return {
          ancillaryServices: ancillaryServices,
        };
      }),
    setBookingCode: (bookingCode) => set(() => ({ bookingCode: bookingCode })),

    setIsGuestDetailsEditable: (isEditable) =>
      set(() => ({ isGuestDetailsEditable: isEditable })),

    setIsFirstNameEditable: (isEditable) =>
      set(() => ({ isFirstNameEditable: isEditable })),
    setIsLastNameEditable: (isEditable) =>
      set(() => ({ isLastNameEditable: isEditable })),
    setIsEmailEditable: (isEditable) =>
      set(() => ({ isEmailEditable: isEditable })),
    setIsCountryEditable: (isEditable) =>
      set(() => ({ isCountryEditable: isEditable })),
    setIsPhoneNoEditable: (isEditable) =>
      set(() => ({ isPhoneNoEditable: isEditable })),
    setIsWhatsAppNoEditable: (isEditable) =>
      set(() => ({ isWhatsAppNoEditable: isEditable })),
    setIsGuestCountEditable: (isEditable) =>
      set(() => ({ isGuestCountEditable: isEditable })),

    setIsVoucherDetailsEditable: (isEditable) =>
      set(() => ({ isVoucherDetailsEditable: isEditable })),

    setIsTripInfoEditable: (isEditable) =>
      set(() => ({ isTripInfoEditable: isEditable })),
    setIsPreferredCheckInTimeEditable: (isEditable) =>
      set(() => ({ isPreferredCheckInTimeEditable: isEditable })),
    setIsPurposeOfVisitEditable: (isEditable) =>
      set(() => ({ isPurposeOfVisitEditable: isEditable })),
    setIsCommentEditable: (isEditable) =>
      set(() => ({ isCommentEditable: isEditable })),

    setIsExpanded: (isExpanded) => set(() => ({ isExpanded: isExpanded })),

    setIsGuestDetailsValid: (isValid) =>
      set(() => ({ isGuestDetailsValid: isValid })),
    setIsFirstNameValid: (isValid) =>
      set(() => ({ isFirstNameValid: isValid })),
    setIsLastNameValid: (isValid) => set(() => ({ isLastNameValid: isValid })),
    setIsEmailValid: (isValid) => set(() => ({ isEmailValid: isValid })),
    setIsCountryValid: (isValid) => set(() => ({ isCountryValid: isValid })),
    setIsPhoneNoValid: (isValid) => set(() => ({ isPhoneNoValid: isValid })),
    setIsWhatsAppNoValid: (isValid) =>
      set(() => ({ isWhatsAppNoValid: isValid })),
    setIsGuestCountValid: (isValid) =>
      set(() => ({ isGuestCountValid: isValid })),

    setSteps: (steps) => set(() => ({steps: steps})),
    setExpiresAt: (expiresAt) => set(() => ({expiresAt: expiresAt})),
    reset: () => set(initialState),
    setCleaningDays: (cleaningDays) => set(() => ({cleaningDays: cleaningDays})),
    setCleaningTimes: (cleaningTimes) => set(() => ({cleaningTimes: cleaningTimes})),
    setIsCleaningAdded: (isCleaningAdded) => set(() => ({isCleaningAdded: isCleaningAdded})),
    setPaymentOptionType: (paymentPlanType) => set(() => ({paymentPlanType: paymentPlanType})),
    setSelectedPaymentOption: (selectedPaymentOption) => set(() => ({selectedPaymentOption: selectedPaymentOption}))
  }))
);

window.store = usePaymentCheckoutStore;

export default usePaymentCheckoutStore;

export const useFirstName = () =>
  usePaymentCheckoutStore((state) => state.firstName);
export const useLastName = () =>
  usePaymentCheckoutStore((state) => state.lastName);
export const useEmail = () => usePaymentCheckoutStore((state) => state.email);
export const useCountryCode = () =>
  usePaymentCheckoutStore((state) => state.countryCode);
export const useCountry = () =>
  usePaymentCheckoutStore((state) => state.country);
export const usePhoneNo = () =>
  usePaymentCheckoutStore((state) => state.phoneNo);
export const useWhatsAppNo = () =>
  usePaymentCheckoutStore((state) => state.whatsAppNo);
export const useGuestCount = () =>
  usePaymentCheckoutStore((state) => state.guestCount);
export const usePreferredCheckInTime = () =>
  usePaymentCheckoutStore((state) => state.preferredCheckInTime);
export const usePurposeOfVisit = () =>
  usePaymentCheckoutStore((state) => state.purposeOfVisit);
export const useComment = () =>
  usePaymentCheckoutStore((state) => state.comment);
export const useVoucherCode = () =>
  usePaymentCheckoutStore((state) => state.voucherCode);

export const useBookingDetails = () =>
  usePaymentCheckoutStore((state) => state.bookingDetails);

export const usePropertyDetails = () =>
  usePaymentCheckoutStore((state) => state.propertyDetails);

export const useLineItems = () =>
  usePaymentCheckoutStore((state) => state.lineItems);

export const useInfoText = () =>
  usePaymentCheckoutStore((state) => state.infoText);

export const useAncillaryServices = () =>
  usePaymentCheckoutStore((state) => state.ancillaryServices);

export const useBookingCode = () =>
  usePaymentCheckoutStore((state) => state.bookingCode);

export const useSetFirstName = () =>
  usePaymentCheckoutStore((state) => state.setFirstName);
export const useSetLastName = () =>
  usePaymentCheckoutStore((state) => state.setLastName);
export const useSetEmail = () =>
  usePaymentCheckoutStore((state) => state.setEmail);
export const useSetCountryCode = () =>
  usePaymentCheckoutStore((state) => state.setCountryCode);
export const useSetCountry = () =>
  usePaymentCheckoutStore((state) => state.setCountry);
export const useSetPhoneNo = () =>
  usePaymentCheckoutStore((state) => state.setPhoneNo);
export const useSetwhatsAppNo = () =>
  usePaymentCheckoutStore((state) => state.setwhatsAppNo);
export const useSetGuestCount = () =>
  usePaymentCheckoutStore((state) => state.setGuestCount);
export const useSetVoucherCode = () =>
  usePaymentCheckoutStore((state) => state.setVoucherCode);
export const useSetPreferredCheckInTime = () =>
  usePaymentCheckoutStore((state) => state.setPreferredCheckInTime);
export const useSetPurposeOfVisit = () =>
  usePaymentCheckoutStore((state) => state.setPurposeOfVisit);
export const useSetComment = () =>
  usePaymentCheckoutStore((state) => state.setComment);
export const useSetBookingDetails = () =>
  usePaymentCheckoutStore((state) => state.setBookingDetails);
export const useSetPropertyDetails = () =>
  usePaymentCheckoutStore((state) => state.setPropertyDetails);
export const useSetLineItems = () =>
  usePaymentCheckoutStore((state) => state.setLineItems);
export const useSetInfoText = () =>
  usePaymentCheckoutStore((state) => state.setInfoText);
export const useSetAncillaryServices = () =>
  usePaymentCheckoutStore((state) => state.setAncillaryServices);
export const useSetBookingCode = () =>
  usePaymentCheckoutStore((state) => state.setBookingCode);

export const useIsGuestDetailsEditable = () =>
  usePaymentCheckoutStore((state) => state.isGuestDetailsEditable);
export const useSetIsGuestDetailsEditable = () =>
  usePaymentCheckoutStore((state) => state.setIsGuestDetailsEditable);

export const useIsFirstNameEditable = () =>
  usePaymentCheckoutStore((state) => state.isFirstNameEditable);
export const useSetIsFirstNameEditable = () =>
  usePaymentCheckoutStore((state) => state.setIsFirstNameEditable);

export const useIsLastNameEditable = () =>
  usePaymentCheckoutStore((state) => state.isLastNameEditable);
export const useSetIsLastNameEditable = () =>
  usePaymentCheckoutStore((state) => state.setIsLastNameEditable);

export const useIsEmailEditable = () =>
  usePaymentCheckoutStore((state) => state.isEmailEditable);
export const useSetIsEmailEditable = () =>
  usePaymentCheckoutStore((state) => state.setIsEmailEditable);

export const useIsCountryEditable = () =>
  usePaymentCheckoutStore((state) => state.isCountryEditable);
export const useSetIsCountryEditable = () =>
  usePaymentCheckoutStore((state) => state.setIsCountryEditable);

export const useIsPhoneNoEditable = () =>
  usePaymentCheckoutStore((state) => state.isPhoneNoEditable);
export const useSetIsPhoneNoEditable = () =>
  usePaymentCheckoutStore((state) => state.setIsPhoneNoEditable);

export const useIsWhatsAppNoEditable = () =>
  usePaymentCheckoutStore((state) => state.isWhatsAppNoEditable);
export const useSetIsWhatsAppNoEditable = () =>
  usePaymentCheckoutStore((state) => state.setIsWhatsAppNoEditable);

export const useIsGuestCountEditable = () =>
  usePaymentCheckoutStore((state) => state.isGuestCountEditable);
export const useSetIsGuestCountEditable = () =>
  usePaymentCheckoutStore((state) => state.setIsGuestCountEditable);

export const useIsVoucherDetailsEditable = () =>
  usePaymentCheckoutStore((state) => state.isVoucherDetailsEditable);
export const useSetIsVoucherDetailsEditable = () =>
  usePaymentCheckoutStore((state) => state.setIsVoucherDetailsEditable);

export const useIsTripInfoEditable = () =>
  usePaymentCheckoutStore((state) => state.isTripInfoEditable);
export const useSetIsTripInfoEditable = () =>
  usePaymentCheckoutStore((state) => state.setIsTripInfoEditable);

export const useIsPreferredCheckInTimeEditable = () =>
  usePaymentCheckoutStore((state) => state.isPreferredCheckInTimeEditable);
export const useSetIsPreferredCheckInTimeEditable = () =>
  usePaymentCheckoutStore((state) => state.setIsPreferredCheckInTimeEditable);

export const useIsPurposeOfVisitEditable = () =>
  usePaymentCheckoutStore((state) => state.isPurposeOfVisitEditable);
export const useSetIsPurposeOfVisitEditable = () =>
  usePaymentCheckoutStore((state) => state.setIsPurposeOfVisitEditable);

export const useIsCommentEditable = () =>
  usePaymentCheckoutStore((state) => state.isCommentEditable);
export const useSetIsCommentEditable = () =>
  usePaymentCheckoutStore((state) => state.setIsCommentEditable);

export const useIsExpanded = () =>
  usePaymentCheckoutStore((state) => state.isExpanded);
export const useSetIsExpanded = () =>
  usePaymentCheckoutStore((state) => state.setIsExpanded);

export const useCurrentPage = () =>
  usePaymentCheckoutStore((state) => state.currentPage);
export const useSetCurrentPage = () =>
  usePaymentCheckoutStore((state) => state.setCurrentPage);

export const useIsGuestDetailsValid = () =>
  usePaymentCheckoutStore((state) => state.isGuestDetailsValid);
export const useIsFirstNameValid = () =>
  usePaymentCheckoutStore((state) => state.isFirstNameValid);
export const useIsLastNameValid = () =>
  usePaymentCheckoutStore((state) => state.isLastNameValid);
export const useIsEmailValid = () =>
  usePaymentCheckoutStore((state) => state.isEmailValid);
export const useIsCountryValid = () =>
  usePaymentCheckoutStore((state) => state.isCountryValid);
export const useIsPhoneNoValid = () =>
  usePaymentCheckoutStore((state) => state.isPhoneNoValid);
export const useIsWhatsAppNoValid = () =>
  usePaymentCheckoutStore((state) => state.isWhatsAppNoValid);
export const useIsGuestCountValid = () =>
  usePaymentCheckoutStore((state) => state.isGuestCountValid);

export const useSetIsGuestDetailsValid = () =>
  usePaymentCheckoutStore((state) => state.setIsGuestDetailsValid);
export const useSetIsFirstNameValid = () =>
  usePaymentCheckoutStore((state) => state.setIsFirstNameValid);
export const useSetIsLastNameValid = () =>
  usePaymentCheckoutStore((state) => state.setIsLastNameValid);
export const useSetIsEmailValid = () =>
  usePaymentCheckoutStore((state) => state.setIsEmailValid);
export const useSetIsCountryValid = () =>
  usePaymentCheckoutStore((state) => state.setIsCountryValid);
export const useSetIsPhoneNoValid = () =>
  usePaymentCheckoutStore((state) => state.setIsPhoneNoValid);
export const useSetIsWhatsAppNoValid = () =>
  usePaymentCheckoutStore((state) => state.setIsWhatsAppNoValid);
export const useSetIsGuestCountValid = () =>
  usePaymentCheckoutStore((state) => state.setIsGuestCountValid);

export const useIsAgree = () =>
  usePaymentCheckoutStore((state) => state.isAgree);
export const useSetIsAgree = () =>
  usePaymentCheckoutStore((state) => state.setIsAgree);

export const useIsBackButtonEnabled = () =>
  usePaymentCheckoutStore((state) => state.isBackButtonEnabled);
export const useSetIsBackButtonEnabled = () =>
  usePaymentCheckoutStore((state) => state.setIsBackButtonEnabled);

export const useSteps = () => usePaymentCheckoutStore((state) => state.steps);
export const useSetSteps = () => usePaymentCheckoutStore((state) => state.setSteps);

export const useExpiresAt = () => usePaymentCheckoutStore((state) => state.expiresAt);
export const useSetExpiresAt = () => usePaymentCheckoutStore((state) => state.setExpiresAt);

export const useResetPaymentCheckoutStore = () =>
  usePaymentCheckoutStore((state) => state.reset);
  
export const useCleaningDays = () => usePaymentCheckoutStore((state) => state.cleaningDays);
export const useSetCleaningDays = () => usePaymentCheckoutStore((state) => state.setCleaningDays);

export const useCleaningTimes = () => usePaymentCheckoutStore((state) => state.cleaningTimes);
export const useSetCleaningTimes = () => usePaymentCheckoutStore((state) => state.setCleaningTimes);
export const useIsCleaningAdded = () =>
  usePaymentCheckoutStore((state) => state.isCleaningAdded);
export const useSetIsCleaningAdded = () =>
  usePaymentCheckoutStore((state) => state.setIsCleaningAdded);

export const usePaymentPlanType = () => usePaymentCheckoutStore((state) => state.paymentPlanType);
export const useSetPaymentOptionType = () => usePaymentCheckoutStore((state) => state.setPaymentOptionType);

export const useSelectedPaymentOption = () => usePaymentCheckoutStore((state) => state.selectedPaymentOption);
export const useSetSelectedPaymentOption = () => usePaymentCheckoutStore((state) => state.setSelectedPaymentOption);
